<template>
  <b-modal id="coaches-calendar-modal" hide-footer :title="`Chọn thông tin`" size="l" :hide-header-close="true"
    no-close-on-backdrop no-close-on-esc>
    <b-container>
      <b-row>
        <!-- <b-col cols="12">
          <basic-select
            label="Chọn khóa"
            placeholder="Chọn khóa"
            name="Course"
            :options="courses"
            :solid="false"
            :required="true"
            :allowEmpty="false"
            track-by="value"
            value-label="text"
            :value.sync="courseSelected"
            v-model="courseSelected"
            changeValueByObject
            data-vv-as="Course"
          />
        </b-col> -->
        <b-col cols="12">
          <basic-select label="Chọn Coach" placeholder="Chọn Coach" name="Coach" :options="coaches" :solid="false"
            :required="true" :allowEmpty="false" track-by="value" value-label="text" v-model="coachSelected"
            :value.sync="coachSelected" changeValueByObject data-vv-as="Coaches" :loading="isLoading" />
        </b-col>
      </b-row>
      <div class="d-flex justify-content-end align-items-center mt-4">

        <b-button class="btn btn-light ml-3" @click="$bvModal.hide('coaches-calendar-modal')" type="button">

          Đóng
        </b-button>
        <b-button class="btn btn-success ml-3" @click="handleSubmit" type="submit">
          <span class="svg-icon">
            <inline-svg src="/media/svg/icons/Neolex/Basic/save.svg" />
          </span>
          Xác nhận
        </b-button>

      </div>
    </b-container>
  </b-modal>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
const { mapActions } = createNamespacedHelpers('calendarCoach');
export default {
  name: 'SelectCourseModal',
  data() {
    return {
      coaches: [],
      courses: [],
      courseSelected: {},
      coachSelected: {},
      isAdminBool: false,
      isCoachBool: false,
      isLoading : false,
    };
  },

  props: ['currentUser'],
  methods: {
    ...mapActions(['setCourse', 'setCoachId', 'setCoachName']),

    async handleSubmit() {
      try {
        this.$store.commit('context/setLoading', true);
          if (Object.keys(this.courseSelected).length === 0) {
          this.$toastr.e({
            title: 'Lỗi !',
            msg: 'Không được để trống khóa học',
          });
          return;
        }
        if (this.isWriteOwnerPermission) {
          if (Object.keys(this.coachSelected).length === 0) {
            this.$toastr.e({
              title: 'Lỗi !',
              msg: 'Vui lòng chọn coach',
            });
            return;
          }
          localStorage.setItem('coachSelectedId', this.coachSelected.value);
        } else{
          localStorage.setItem('coachSelectedId', this.currentUser?.id);
        }
        this.setCourse(this.courseSelected);
        this.setCoachId(this.coachSelected.value);
        this.setCoachName(this.coachSelected.text);

        await this.$emit('selected-success');
        this.$bvModal.hide('coaches-calendar-modal');
      } catch (error) {
        this.$toastr.e({
          title: 'Lỗi !',
          msg: error,
        });
      } finally {
        this.$store.commit('context/setLoading', false);

      }

    },

    async getDataCourses() {

      await this.$api
        .get('Courses')
        .then(({ data }) => {
          const { items } = data;
          this.courses = items?.map((c) => {
            return {
              text: c?.name,
              value: c?.id,
              time: c?.startDatetime,
            };
          });
        })
        .catch((error) => {
          this.$toastr.e({
            title: 'Lỗi',
            msg: error,
          });
          return Promise.reject(error);
        });
    },

    async checkRole(roleName) {

      const params = {
        id: this.currentUser?.id,
      };

      const { meta, data, error } = await this.$api.get(
        '/Admin/Account/portal/PrepareCreateUpdateFormItem',
        {
          params: params,
        },
      );

      if (!meta.success) {
        return this.$toastr.e({
          title: 'Lỗi !',
          msg: error.message,
          preventDuplicates: true,
        });
      }

      return data.roleList.some(
        (role) => role.text === roleName && role.selected == true,
      );
    },

    async isAdmin() {
      this.isAdminBool = await this.checkRole('Admin');
      localStorage.setItem('isAdminRole', this.isAdminBool);
      return this.isAdminBool;
    },

    async isCoach() {
      this.isCoachBool = await this.checkRole('Coach');
      return this.isCoach;
    },

    async choosenCourse() {
      this.isLoading = true;
  this.$store.commit('context/setLoading', true);
  
  try {
    const { data } = await this.$api.get(`Courses/CoachInCourse?courseId=${this.courseSelected}`);
    
    if (this.isWriteOwnerPermission) {
      this.coaches = [
        { text: 'Xem tất cả', value: 'All' },
        ...data?.map((c) => ({
          text: c?.fullName,
          value: c?.id,
        })),
      ];
    } else {
      // Filter to only show current user's data from API response
      this.coaches = data
        ?.filter(coach => coach.id === this.currentUser?.id)
        ?.map(c => ({
          text: c.fullName,
          value: c.id,
        })) || [];
    }
  } catch (error) {
    this.$toastr.e({
      title: 'Lỗi',
      msg: error,
    });
    return Promise.reject(error);
  } finally {
    this.$store.commit('context/setLoading', false);
    this.isLoading = false;

  }
}
      
    

  },
  computed: {
    isWriteOwnerPermission() {
      console.log('this.$route.meta.isWriteOwnerPermission', this.$route.meta);
      return this.$route.meta.isWriteOwnerPermission;
    },
  },
  watch: {
    courseSelected: {
      handler(newValue) {
        if (newValue) {
          console.log("newValue",newValue.value);
       //   this.coachSelected = newValue[0];
          this.choosenCourse();
        }
      },
      deep: true,
    },
  },
  async mounted() {
    await Promise.all([this.getDataCourses()]);
    this.$bvModal.show('coaches-calendar-modal');
    this.courseSelected = "350a3050-c0f7-11ef-b57a-03ea338ae610"
    this.choosenCourse();
  },

  beforeMount() {
    Promise.all([this.getDataCourses()])
      .then(() => {
      })
      .catch(error => {
        console.error('Đã xảy ra lỗi khi gọi API:', error);
      });
  }

};
</script>
